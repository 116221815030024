import styled from '@emotion/styled';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from './icon';
import TextLink from './links/text-link';
import TechList from './tech-list';
import { mq } from './_shared/media';
import { ButtonLink } from './links/button-link';
import { StyledContentLink } from './_shared/styled-content-link';
import { StyledH1, StyledH2 } from './_shared/styled-headings';
import { StyledImageContainer } from './_shared/styled-image-container';
import { formationsBaseUrl } from './_config/site-base-urls';
import { contentBox, flexCenter, flexEnd } from './_shared/styled-mixins';
import { StyledSection } from './_shared/styled-section';

const StyledFeaturedProject = styled.article`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2.5rem;
  padding: 2.5rem 0;

  ${mq.gt.sm} {
    grid-template-columns: repeat(2, 1fr);
  }

  &:nth-of-type(even) {
    direction: rtl;
  }
  &:nth-of-type(even) * {
    direction: ltr;
  }
`;
const StyledDurationOverlay = styled.div`
  pointer-events: none;
  z-index: 1;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 3rem;
  width: 3rem;
  color: var(--title-color);
  background: var(--bg-code);
  align-items: center;
  border-radius: var(--radius);
  font-weight: 500;
  line-height: 1rem;
`;
const StyledProjectInfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const StyledDescription = styled.section`
  ${contentBox}
  max-height: 180px;
  position: relative;
  padding: 10px;

  > p {
    height: 100%;
    margin: 0;
    font-size: 0.8rem;
    overflow: hidden;
  }
`;
const StyledLinkContainer = styled.section`
  ${flexEnd};
  margin: 10px 0;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--body-color);

    &:hover {
      color: var(--primary-color);
    }
  }

  & svg {
    margin: 0 0.5rem;
  }
`;
const StyledArchiveContainer = styled.div`
  ${flexCenter};
  width: 100%;
  margin-top: 2.5rem;
`;
const StyledCtaOverlay = styled.div`
  a {
    border-radius: 0 !important;
  }
`;

const FeaturedHome = ({ featured }) => {
  const featuredHome = featured.map(formation => {
    const coverImage = formation.coverImage ? formation.coverImage.fluid : null;

    const { title, duration, promoLink } = formation;
    const description = formation.description.description;
    // const link = formationsBaseUrl + '/' + formation.slug;
    const link = promoLink;
    return (
      <StyledFeaturedProject key={formation.id}>
        {/* <a
          aria-label={`Formation ${title}`}
          href={link}
          target='_blank'
          rel="noopener"
        > */}
        {coverImage && (
          <StyledImageContainer hasHover>
            <Img fluid={coverImage} />
            <StyledDurationOverlay>
              <span>{duration}</span>
            </StyledDurationOverlay>
            <StyledCtaOverlay>
              <ButtonLink label="Apprendre Maintenant" link={link} />
            </StyledCtaOverlay>
          </StyledImageContainer>
        )}
        {/* </a> */}
        <StyledProjectInfoContainer>
          {/* <StyledContentLink href={link} target="_blank" rel="noopener"> */}
          <StyledH2>{title}</StyledH2>
          {/* </StyledContentLink> */}
          <StyledDescription>
            <p>{description}</p>
          </StyledDescription>
          <TechList techs={formation.techs} />
        </StyledProjectInfoContainer>
      </StyledFeaturedProject>
    );
  });

  return (
    <StyledSection id="formations">
      <StyledH1>Les Formations</StyledH1>
      {featuredHome}
      {(featuredHome.length > 3) && (<StyledArchiveContainer>
        <TextLink label="Voir les formations" link={formationsBaseUrl} />
      </StyledArchiveContainer>)}
    </StyledSection>
  );
};

FeaturedHome.propTypes = {
  featured: PropTypes.array.isRequired,
};

export default FeaturedHome;
