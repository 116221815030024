import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import About from '../components/about';
import CardGrid from '../components/card-grid';
import Contact from '../components/contact';
import FeaturedHome from '../components/featured-home';
import Hero from '../components/hero';
import Facts from '../components/facts';
import Layout from '../components/layout';
import RecentPosts from '../components/recent-posts';
import SEO from '../components/seo';
import { indexMenuLinks } from '../components/_config/menu-links';

const Index = ({ data }) => {
  const heroData = {
    headline: data.hero.frontmatter.headline,
    tagline1: data.hero.frontmatter.tagline_a,
    tagline2: data.hero.frontmatter.tagline_b,
    description: data.hero.html,
    introduction: data.hero.frontmatter.introduction,
    ctaLabel: data.hero.frontmatter.cta_label,
    ctaLink: data.hero.frontmatter.cta_link,
  };


  // https://ibaslogic.com/gatsby-with-contentful-cms/
  // https://www.trality.com/
  return (
    <Layout menuLinks={indexMenuLinks}>
      <SEO image='' title="Accueil" keywords={data.site.siteMetadata.defaultKeywords}/>
      <Hero data={heroData} />
      <FeaturedHome featured={data.featuredCourses.nodes} />
      <Facts/>
      <About data={data.about} />
      {/* <CardGrid cards={data.cards.frontmatter.cards} 
                description={data.cards.html} 
                title="Quelques Avis" 
                id="avis" /> */}
      {/* <RecentPosts data={data.blog.nodes} /> */}
      <Contact data={data.contact} />
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  {
    site {
      siteMetadata {
        author
        defaultKeywords: keywords
      }
    }

    hero: markdownRemark(fileAbsolutePath: { regex: "/content/sections/hero/" }) {
      frontmatter {
        introduction
        headline
        tagline_a
        tagline_b
        cta_label
        cta_link
      }
      html
    }

    about: markdownRemark(fileAbsolutePath: { regex: "/content/sections/about/" }) {
      frontmatter {
        title
        techs
        about_image {
          childImageSharp {
            fixed(width: 200, height: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      html
    }

    cards: markdownRemark(fileAbsolutePath: { regex: "/content/sections/cards/" }) {
      frontmatter {
        cards {
          name
          card_image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          rating
          description
        }
      }
      html
    }

    featuredCourses: allContentfulFormation(limit: 5, sort: {order: DESC, fields: date}) {
      nodes {
        id
        title
        duration
        techs
        slug
        promoLink
        date(formatString: "D MMMM, YYYY")
        description {
          description
        }
        coverImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
        }
      }
    }

    blog: allContentfulArticle(limit: 5, sort: {order: DESC, fields: date}) {
      nodes {
        id
        title
        tags
        date(formatString: "D MMMM, YYYY")
        slug
        description {
          description
        }
        coverImage {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }

    contact: markdownRemark(fileAbsolutePath: { regex: "/content/sections/contact/" }) {
      frontmatter {
        email
      }
      html
    }
  }
`;
