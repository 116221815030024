import styled from '@emotion/styled';
import Img from 'gatsby-image';
import React from 'react';
import TechList from './tech-list';
import SocialIcons from './social-icons';
import { socialIconList } from './_config/social-icon-list';
import { mq } from './_shared/media';
import { StyledH1, StyledH2 } from './_shared/styled-headings';
import { StyledStaticImageContainer } from './_shared/styled-image-container';
import { StyledSection } from './_shared/styled-section';

const StyledAboutContainer = styled.article`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2.5rem;
  padding: 2.5rem 0;
  align-items: center;

  ${mq.gt.sm} {
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 200px 3fr;
  }
`;
const StyledTextSection = styled.section`
  white-space: pre-line;

  & > p {
    margin: 0 !important;
    ${mq.gt.sm} {
      font-size: 0.9rem;
    }
  }
`;

const StyledSocialIcons = styled.div`
  float: left;
`;

const About = ({ data }) => {
  const {
    frontmatter: { title, techs, about_image },
    html,
  } = data;

  const image = about_image ? about_image.childImageSharp.fixed : null;

  return (
    <StyledSection id="a-propos">
      <StyledH1>Qui Suis-Je?</StyledH1>
      <StyledAboutContainer>
        {image && (
          <StyledStaticImageContainer>
            <Img fixed={image} objectFit="contain" />
          </StyledStaticImageContainer>
        )}
        <div>
          <StyledH2>{title}</StyledH2>
          <StyledTextSection dangerouslySetInnerHTML={{ __html: html }} />
          <TechList techs={techs} />
          <StyledSocialIcons>
            <SocialIcons icons={socialIconList} />
          </StyledSocialIcons>
        </div>
      </StyledAboutContainer>
    </StyledSection>
  );
};

About.propTypes = {};

export default About;
